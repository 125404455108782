.function {
    display: flex;
}
.input-filter {
    height: 38px; 
    margin: 10px 0px 0px 0px
}

@media only screen and (max-width: 576px) {
    .table-device-mobile {
        display: none;
    }
}
@media only screen and (max-width: 768px) {
    .table-device-tablet {
        display: none;
    }
}
@media only screen and (max-width: 1024px) {
    .function {
        display: block;
    }
    .table-device-large-size-tablet {
        display: none;
    }
    .input-filter {
        width: 100%; 
        margin: 10px 10px 0px 0px
    }
    .nav-mobile {
        color: white;
    }
    .nav-link-mobile {
        padding: 0px !important;
    }
}
